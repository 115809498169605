import React from "react";
import "./footer.scss";
const Footer = () => {
  return (
    <>
      <section className="mainfooter">
        <div className="footerinner">
          <img src="\assets\footorbg.svg" />
          <div className="innnerf">
            <div className="proooo">
              <p className="para66">©2024 Prodex. All Rights Reserved.</p>
              {/* <img className="reservedimg" src="\assets\Line.png"/> */}
            </div>
            {/* <div className="textdiv">
              <p className="para666">privacy policy</p>
              <img src="\assets\Line.png"/>
              <p className="para6666">Terms</p>
              <img src="\assets\Line.png"/>
          
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
