import React from "react";
import "./mission.scss";
const Mission = () => {
  const handleClickBtn = () => {
    window.open("https://t.me/prodexCom", "_blank");
  };
  return (
    <>
      <section className="mainbanner3" id="mission">
        <div className="mainparent3">
          <div className="parent3">
            <p className="para1">Mission Statement</p>
            <p className="para2">////</p>
          </div>
          <div className="parent111">
            <div className="parent11">
              <h1
                className="para3 wow animate__animated animate__fadeInLeft"
                data-wow-duration=".2s"
              >
                To democratize product information through a decentralized
                platform that rewards contributions
              </h1>
              <p
                className="para4 wow animate__animated animate__fadeInLeft"
                data-wow-duration=".3s"
              >
                Fosters a global marketplace for goods and services, and
                enhances user and supplier interactions without the need for
                advanced technical knowledge.
              </p>
              <button
                className="para5 wow animate__animated animate__fadeInLeft"
                data-wow-duration=".4s"
                onClick={() => handleClickBtn()}
              >
                GET IN TOUCH{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M2.00049 9.38152L12.2931 9.38152L8.64675 13.0279L9.35371 13.7349L14.2071 8.88154L9.35371 4.02821L8.64675 4.73522L12.2931 8.38156L2.00049 8.38156L2.00049 9.38152Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
            <div className="img3div">
              <img className="img-fluid" src="\assets\part3.svg" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
