import React from "react";
import "./community.scss";

const Community = () => {
  const handleClickBtn = () => {
    window.open("https://t.me/prodexCom", "_blank");
  };

  return (
    <>
      <section className="mainbanner9">
        <div className="mainparent9">
          <img className="lower img-fluid" src="\assets\lowerpic.svg" />
          <img
            className="lowerrrr img-fluid d-none"
            src="\assets\circledi.svg"
          />
          <img className="circleee img-fluid " src="\assets\circleimggg.svg" />
          <div className="parent9">
            <h1
              className="para111 wow animate__animated animate__fadeInLeft "
              data-wow-delay=".2s "
            >
              Join the Prodex Community
            </h1>
            <p
              className="para112 wow animate__animated animate__fadeInLeft "
              data-wow-delay=".3s "
            >
              Become part of the future of product information – accurate,
              inclusive, and decentralized.
            </p>
            <button
              className="para113 wow animate__animated animate__fadeInLeft "
              data-wow-delay=".4s"
              onClick={() => handleClickBtn()}
            >
              GET IN TOUCH{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M2.50049 8.50017L12.7931 8.50017L9.14675 12.1465L9.85371 12.8535L14.7071 8.00019L9.85371 3.14687L9.14675 3.85387L12.7931 7.50021L2.50049 7.50021L2.50049 8.50017Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Community;
