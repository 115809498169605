import React, { useState } from "react";
import "./faqs.scss";
import Accordion from "react-bootstrap/Accordion";

const Faqs = () => {
  const [activeKey, setActiveKey] = useState("0");
  const handleSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <>
      <section className="mainbanner8" id="faqs">
        <div className="mainparent8">
          <div className="parent8">
            <p className="para1">FAQs</p>
            <p className="para2">////</p>
          </div>
          <div className="parentbar111">
            <h1 className="para444444 animate__animated animate__heartBeat">
              frequently asked questions
            </h1>
            <div className="mainfaqs">
              <div className="innerfaq">
                <Accordion activeKey={activeKey} onSelect={handleSelect}>
                  <Accordion.Item
                    eventKey="0"
                    className={activeKey === "0" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q01.</span>What is Prodex’s primary mission?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Prodex’s mission is to democratize product information
                        by creating a decentralized platform that rewards user
                        contributions and fosters a global marketplace for goods
                        and services. This platform aims to make it easier for
                        everyone to access, update, and verify product
                        information.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="1"
                    className={activeKey === "1" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q02. </span>How does Prodex address existing
                        challenges in product catalog systems?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Prodex offers a decentralized product catalog system
                        with real-time updates, community-driven contributions,
                        and blockchain technology to ensure transparency, reduce
                        reliance on manual data entry, and mitigate biases and
                        inaccuracies associated with centralized control.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="2"
                    className={activeKey === "2" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q03.</span>What are the key features of the Prodex
                        platform?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Key features include a decentralized product database,
                        real-time updates, community-driven contributions,
                        comprehensive search and filtering options, a token
                        economy, and smart contracts for dispute resolution.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="3"
                    className={activeKey === "3" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q04.</span>How does the tokenomics of Prodex work?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Prodex uses $PXD tokens for transactions within the
                        platform. Tokens are minted as new products are added,
                        with incentives for community contributions. The reward
                        structure includes halving mechanisms and transaction
                        fees to manage supply and value.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="4"
                    className={activeKey === "4" ? "active" : ""}
                  >
                    <Accordion.Header>
                      <p>
                        <span>Q05.</span>What is the long-term vision for
                        Prodex?
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="para">
                        Prodex aims to become a leading decentralized product
                        index, prioritizing reliability, user-centricity, and
                        innovative blockchain technology. The platform focuses
                        on fostering a robust, community-driven ecosystem where
                        users actively contribute to and govern its evolution.
                        Prodex is committed to driving technological innovation,
                        continuously pushing the boundaries of blockchain and
                        e-commerce integration to set new standards for
                        transparency, efficiency, and user engagement in the
                        digital marketplace.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
