import React from 'react';
import { createRoot } from 'react-dom/client'; // Import from react-dom/client
import App from './App';
import { ToastContainer } from 'react-toastify';

const root = createRoot(document.getElementById('root'));

root.render(
  <>
  <ToastContainer style={{ fontSize: "14px", zIndex: "9999999999999999" }}
        theme="dark"
        position="top-right"/>
    <App />
   
  </>
);
