import React from "react";
import "./features.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Features = () => {
  const options = {
    margin: 20,
    responsiveClass: true,
    loop: true,

    autoplay: false,
    navText: ["Prev", "Next"],
    responsive: {
      0: {
        items: 1,
      },
      375: {
        items: 1,
        nav: false,
        dots: false,
        dotsEach: false,
      },
      400: {
        items: 1.3,
        nav: false,
        dots: false,
        dotsEach: false,
      },
      600: {
        items: 1.5,
        nav: false,
        dots: false,
      },
      700: {
        items: 1.5,
        nav: false,
        dots: false,
      },
      1000: {
        items: 2.5,
        nav: false,
        dots: false,
      },
      1300: {
        items: 3.5,
        nav: false,
        dots: false,
      },
      1440: {
        items: 3.5,
        nav: false,
        dots: false,
      },
      2560: {
        items: 7,
        nav: false,
        dots: false,
      },
    },
  };
  return (
    <>
      <section className="mainbanner4" id="features">
        <div className="mainparent4">
          <div className="parent4">
            <p className="para1 animate__animated animate__backInRight">
              Key Features
            </p>
            <p className="para2">////</p>
          </div>
          <div className="cardshead">
            <OwlCarousel className="owl-theme" {...options}>
              <div
                className="card1 
              wow animate__animated animate__fadeInLeft"
                data-wow-duration=".8s"
              >
                <p className="para6">01 //</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="89"
                  height="90"
                  viewBox="0 0 89 90"
                  fill="none"
                >
                  <path
                    d="M48.8765 21.921L48.6577 10.4414V5.15359M72.0371 5.15359V84.6947C72.0371 87.5734 66.0406 88.9967 60.1228 88.9588C58.2708 88.9967 56.1883 88.3375 53.8755 86.9813"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="2.613"
                  />
                  <path
                    d="M60.3474 8.91308C66.8034 8.91308 72.0371 7.15025 72.0371 4.97569C72.0371 2.80113 66.8034 1.0383 60.3474 1.0383C53.8914 1.0383 48.6577 2.80113 48.6577 4.97569C48.6577 7.15025 53.8914 8.91308 60.3474 8.91308Z"
                    fill="#81D4FA"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="2.613"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M37.3355 87.5092C39.0826 88.4484 41.1563 88.9325 43.5537 88.9588C49.4715 88.9967 55.465 87.5763 55.465 84.6976V25.6426M32.0857 25.6426V30.9304V41.7393"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="2.613"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M43.7754 21.5273C50.2327 21.5273 55.465 23.2918 55.465 25.4647C55.465 27.6405 50.2327 29.4021 43.7754 29.4021C37.321 29.4021 32.0857 27.6405 32.0857 25.4647C32.0857 23.2918 37.321 21.5273 43.7754 21.5273Z"
                    fill="#81D4FA"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="2.613"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M39.9604 46.1316V84.6947C39.9604 87.5734 33.9639 88.9938 28.0462 88.9588C22.2771 88.9238 16.5811 87.5005 16.5811 84.6947V46.1316"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="2.613"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28.2707 49.964C21.8134 49.964 16.5811 48.1995 16.5811 46.0266C16.5811 43.8509 21.8134 42.0892 28.2707 42.0892C34.7251 42.0892 39.9604 43.8509 39.9604 46.0266C39.9604 48.1995 34.7251 49.964 28.2707 49.964Z"
                    fill="#81D4FA"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="2.613"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <h1 className="para7">A Decentralized Product Database</h1>
                <p className="para8">
                  Decentralized product database for transparency and trust.
                </p>
              </div>
              <div
                className="card1 wow animate__animated animate__fadeInLeft"
                data-wow-duration=".5s"
              >
                <p className="para6">02 //</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="93"
                  height="90"
                  viewBox="0 0 93 90"
                  fill="none"
                >
                  <g clip-path="url(#clip0_67_24323)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.5609 63.2755L42.2772 53.4027C42.6474 53.2176 43.0512 53.2344 43.4045 53.4531C43.7537 53.6719 43.9514 54.0252 43.9514 54.4417V74.4188C43.9514 75.1381 43.6107 75.7901 42.9671 76.1098L22.2791 86.4328M45.2218 26.2744L64.9381 16.4015C65.3083 16.2164 65.7121 16.2332 66.0655 16.452C66.4146 16.6707 66.6123 17.0283 66.6123 17.4405V37.4218C66.6123 38.1412 66.2716 38.789 65.628 39.1087L44.94 49.4316M69.6663 62.3291L89.3784 52.4562C89.7486 52.2711 90.1524 52.2879 90.5057 52.5067C90.8549 52.7254 91.0526 53.0788 91.0526 53.4952V73.4765C91.0526 74.1958 90.7119 74.8437 90.0683 75.1634L69.3802 85.4863"
                      fill="#81D4FA"
                    />
                    <path
                      d="M22.5609 63.2755L42.2772 53.4027C42.6474 53.2176 43.0512 53.2344 43.4045 53.4531C43.7537 53.6719 43.9514 54.0252 43.9514 54.4417V74.4188C43.9514 75.1381 43.6107 75.7901 42.9671 76.1098L22.2791 86.4328M45.2218 26.2744L64.9381 16.4015C65.3083 16.2164 65.7121 16.2332 66.0655 16.452C66.4146 16.6707 66.6123 17.0283 66.6123 17.4405V37.4218C66.6123 38.1412 66.2716 38.789 65.628 39.1087L44.94 49.4316M69.6663 62.3291L89.3784 52.4562C89.7486 52.2711 90.1524 52.2879 90.5057 52.5067C90.8549 52.7254 91.0526 53.0788 91.0526 53.4952V73.4765C91.0526 74.1958 90.7119 74.8437 90.0683 75.1634L69.3802 85.4863"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.60376 53.4531L21.5262 43.2437C22.1445 42.924 22.8639 42.924 23.4822 43.2437L43.4046 53.4531M24.2647 16.452L44.1871 6.24257C44.8054 5.92708 45.5248 5.92708 46.1431 6.24257L66.0655 16.452M48.7092 52.5067L68.6274 42.2973C69.2457 41.9776 69.9651 41.9776 70.5834 42.2973L90.5058 52.5067"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.73111 53.4027L21.808 62.451C22.3506 62.7076 22.6619 63.2377 22.6619 63.8392V85.764C22.6619 86.0458 22.5231 86.2898 22.2791 86.4328C22.0352 86.5758 21.7617 86.5716 21.5135 86.4412L2.04123 76.1098C1.40603 75.7733 1.05688 75.1381 1.05688 74.4188V54.4417C1.05688 54.0252 1.25459 53.6719 1.60374 53.4531C1.9571 53.2344 2.35672 53.226 2.73111 53.4027ZM25.392 16.4015L44.4689 25.4541C45.0115 25.7107 45.3228 26.2365 45.3228 26.8381V48.7628C45.3228 49.0488 45.184 49.2928 44.94 49.4316C44.696 49.5789 44.4226 49.5747 44.1744 49.4401L24.7021 39.1087C24.0669 38.7721 23.7178 38.1412 23.7178 37.4218V17.4405C23.7178 17.0283 23.9155 16.6707 24.2646 16.452C24.618 16.2332 25.0176 16.2248 25.392 16.4015ZM49.8323 52.4562L68.9092 61.5088C69.4518 61.7654 69.7631 62.2912 69.7631 62.8927V84.8175C69.7631 85.0993 69.6243 85.3433 69.3803 85.4863C69.1405 85.6294 68.8629 85.6251 68.6147 85.4947L49.1424 75.1634C48.5114 74.8268 48.1581 74.1958 48.1581 73.4765V53.4952C48.1581 53.0788 48.3558 52.7254 48.7091 52.5067C49.0583 52.2879 49.4621 52.2795 49.8323 52.4562Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_67_24323">
                      <rect width="92.1094" height="90" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h1 className="para7">Engaging Users and Manufacturers</h1>
                <p className="para8">
                  Real-time updates from users and manufacturers
                </p>
              </div>
              <div
                className="card1 wow animate__animated animate__fadeInLeft"
                data-wow-duration=".3s"
              >
                <p className="para6">03 //</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="88"
                  height="90"
                  viewBox="0 0 88 90"
                  fill="none"
                >
                  <g clip-path="url(#clip0_67_24334)">
                    <path
                      d="M38.3357 27.9248C29.0784 29.8727 22.1294 38.0844 22.1294 47.9195C22.1294 59.2035 31.2769 68.351 42.561 68.351C48.419 68.351 53.7017 65.8827 57.4257 61.932M74.964 73.1181C67.4541 82.7646 55.7332 88.9687 42.561 88.9687C19.8903 88.9687 1.50928 70.5901 1.50928 47.9195C1.50928 26.8147 17.4387 9.42919 37.9275 7.12799"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M83.1378 41.6795C83.4481 43.7133 83.6104 45.7973 83.6104 47.9195C83.6104 54.1022 82.2426 59.9674 79.7934 65.2262C79.6335 65.57 79.3637 65.8039 79.0009 65.9114C78.6404 66.0212 78.2847 65.9758 77.9625 65.7801L62.3649 56.2601C61.8421 55.9403 61.6201 55.3148 61.8254 54.7371C62.5797 52.6054 62.9927 50.309 62.9927 47.9195C62.9927 47.5256 62.9808 47.1317 62.9593 46.745C62.9235 46.1339 63.3102 45.5944 63.9022 45.4345L81.5336 40.6363C81.8989 40.536 82.2522 40.5909 82.5673 40.7986C82.8871 41.0039 83.0805 41.3047 83.1378 41.6795ZM84.539 33.0786L62.2217 39.2446C61.632 39.4069 61.0233 39.1396 60.744 38.5953C57.9749 33.2051 52.8808 29.2067 46.7864 27.9248C46.1872 27.8006 45.7671 27.285 45.7671 26.6715V3.59742C45.7671 2.83354 46.0655 2.17707 46.6384 1.671C47.2113 1.16731 47.9012 0.957244 48.6603 1.05273C65.6281 3.23458 79.7839 14.3897 86.2196 29.6077C86.518 30.3119 86.4989 31.0352 86.1647 31.7227C85.8329 32.4126 85.2767 32.8757 84.539 33.0786Z"
                      fill="#81D4FA"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_67_24334">
                      <rect width="87.9389" height="90" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h1 className="para7">Community Contribution</h1>
                <p className="para8">
                  Community-driven contributions with token-based rewards.
                </p>
              </div>
              <div
                className="card1 
              wow animate__animated animate__fadeInLeft"
                data-wow-duration=".1s"
              >
                <p className="para6">04 //</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="71"
                  height="90"
                  viewBox="0 0 71 90"
                  fill="none"
                >
                  <g clip-path="url(#clip0_67_24344)">
                    <path
                      d="M70.7409 2.12698V26.5551C70.7409 30.4706 68.5731 33.8184 64.9999 35.4241L52.6049 40.9899C51.4138 41.5242 50.6903 42.6401 50.6903 43.9453V45V46.0547C50.6903 47.3599 51.4138 48.4758 52.6049 49.0101L64.9999 54.5759C68.5731 56.1816 70.7409 59.5294 70.7409 63.4449V88.0177M16.6084 88.0177V63.4449C16.6084 59.5294 18.7763 56.1816 22.3495 54.5759L34.7444 49.0101C35.9355 48.4758 36.659 47.3599 36.659 46.0547V45V43.9453C36.659 42.6401 35.9355 41.5242 34.7444 40.9899L22.3495 35.4241C18.7763 33.8184 16.6084 30.4706 16.6084 26.5551V2.12698"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.5597 60.74L25.0544 71.2954C22.4636 72.9512 21.5953 72.723 21.5953 76.1015V82.4214C21.5953 83.5011 22.4775 84.3833 23.56 84.3833H63.7893C64.8718 84.3833 65.7512 83.5011 65.7512 82.4214V76.1015C65.7512 72.7509 64.9331 72.9818 62.3784 71.3483L45.7897 60.74C44.4706 59.8968 42.8788 59.8968 41.5597 60.74ZM24.7038 29.3018C22.0656 27.6154 21.1807 27.8463 21.1807 24.4039V20.8279C21.1807 19.7259 22.0795 18.827 23.1816 18.827H64.1678C65.2698 18.827 66.1687 19.7259 66.1687 20.8279V24.4039C66.1687 27.8185 65.3338 27.582 62.7318 29.2461C57.8312 31.2943 52.5604 33.7321 47.5512 35.7302C46.0012 36.348 45.0355 37.7757 45.0355 39.4454V43.0075C45.0355 43.6642 44.4956 44.2013 43.8389 44.2013H43.5105C42.8537 44.2013 42.3166 43.6642 42.3166 43.0075V39.4454C42.3166 37.7757 41.3482 36.348 39.7953 35.7302C34.814 33.746 29.5822 31.3333 24.7038 29.3018Z"
                      fill="#81D4FA"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.5527 88.9695H74.3864"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.5527 1.03053H74.3864"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-miterlimit="2.613"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_67_24344">
                      <rect width="87.9389" height="90" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h1 className="para7">Advanced Search & Filtering</h1>
                <p className="para8">Advanced search and filtering options.</p>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
