import React from "react";
import "./tokenomics.scss";

const Tokenomics = () => {
  return (
    <>
      <section className="mainbanner5" id="tokenomics">
        <div className="mainparent5">
          <div className="parent5">
            <p
              className="para1 wow animate__animated animate__zoomIn"
              data-wow-duration="2s"
              data-wow-delay="2s"
            >
              Tokenomics
            </p>
            <p className="para2">////</p>
          </div>
          <div className="parent55">
            <div className="phonedivvv d-none">
              <h1
                className="para11 wow animate__animated animate__backInRight"
                data-wow-duration="2s"
                data-wow-delay="2s"
              >
                Prodex Tokenomics
              </h1>
              <p
                className="para12 animate__animated animate__backInRight"
                data-wow-duration="2s"
                data-wow-delay="2s"
              >
                The total supply of PXD tokens starts at zero and is minted as
                new products are added to the platform. The distribution is
                structured to accommodate all essential ecosystem components
                effectively.
              </p>
            </div>
            <div
              className='imgdiv wow animate__animated animate__zoomIn"'
              data-wow-duration="2s"
              data-wow-delay="2s"
            >
              <img className="img-fluid" src="\assets\circleimage.svg" />
            </div>
            <div className="parent556 wow animate__animated animate__bounceInDown">
              <div className="displaydivv">
                <h1 className='para11 wow animate__animated animate__zoomIn" data-wow-duration="2s" data-wow-delay="2s'>
                  Prodex Tokenomics
                </h1>
                <p className="para12 wow animate__animated animate__backInRigh">
                  The total supply of PXD tokens starts at zero and is minted as
                  new products are added to the platform. The distribution is
                  structured to accommodate all essential ecosystem components
                  effectively.
                </p>
              </div>

              <div className="para13">
                <p className="para14 wow animate__animated animate__backInRigh">
                  Supervisors Incentives
                </p>
                <p className="para15 wow animate__animated animate__backInRigh">
                  60%
                </p>
              </div>
              <div className="para13 wow animate__animated animate__backInRigh">
                <p className="para14 wow animate__animated animate__backInRigh">
                  Exchange & Launchpads
                </p>
                <p className="para15 wow animate__animated animate__backInRigh">
                  8%
                </p>
              </div>
              <div className="para13">
                <p className="para14 wow animate__animated animate__backInRigh">
                  Early Contributors & Advisors
                </p>
                <p className="para15 wow animate__animated animate__backInRigh">
                  5%
                </p>
              </div>

              <div className="para13">
                <p className="para14 wow animate__animated animate__backInRigh">
                  Airdrops
                </p>
                <p className="para15 wow animate__animated animate__backInRigh">
                  7%
                </p>
              </div>
              <div className="para13">
                <p className="para14 wow animate__animated animate__backInRigh">
                  Partnerships
                </p>
                <p className="para15 wow animate__animated animate__backInRigh">
                  5%
                </p>
              </div>
              <div className="para13">
                <p className="para14 wow animate__animated animate__backInRigh">
                  Development
                </p>
                <p className="para15 wow animate__animated animate__backInRigh">
                  15%
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tokenomics;
