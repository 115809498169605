import React from "react";
import "./vision.scss";

const Vision = () => {
  return (
    <>
      <section className="mainbanner2" id="vision">
        <div className="mainparent2">
          <div className="parent2  ">
            <p className="para1">Vision Statement</p>
            <p className="para2">////</p>
          </div>
          <div className="parent111">
            <div
              className="img2divv wow animate__animated animate__fadeInLeft"
              data-wow-duration=".2s"
            >
              <img className="img-fluid" src="\assets\part2.svg" />
            </div>
            <div
              className="parent11 wow animate__animated animate__fadeIn"
              data-wow-delay=".2s"
            >
              <h1 className="para3">
                To create the most comprehensive and accessible worldwide
                product index
              </h1>
              <p
                className="para4 wow animate__animated animate__fadeIn"
                data-wow-delay=".3s"
              >
                leveraging blockchain technology to ensure transparency,
                security, and community-driven content creation.
              </p>
              <a
                href="/Prodex Whitepaper.pdf"
                target="_blank"
                className="para5 wow animate__animated animate__fadeIn"
                data-wow-delay=".5s"
              >
                Read Whitepaper{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M2.00049 9.38152L12.2931 9.38152L8.64675 13.0279L9.35371 13.7349L14.2071 8.88154L9.35371 4.02821L8.64675 4.73522L12.2931 8.38156L2.00049 8.38156L2.00049 9.38152Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vision;
