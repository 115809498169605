import React from "react";
import "./banner.scss";
import { HashLink } from "react-router-hash-link";

const Banner = () => {
  return (
    <>
      <section className="mainbanner" id="home">
        <div className="mainparent">
          <div className="innercontent">
            <h2 className="wow animate__animated animate__fadeIn">
              Community based Product Catalog with Real-Time, Decentralized
              Updates.
            </h2>
            <p
              className="animate__animated animate__fadeIn"
              style={{ animationDelay: "0.5s" }}
            >
              Welcome to Prodex, a decentralized product index where anyone can
              add products and earn rewards. By contributing to our
              international product index, you receive tokens and share in the
              revenue generated by the product pages. Join us to revolutionize
              product listing with economic incentives and transparency.
            </p>
            <div
              className="buttonshead wow animate__animated animate__fadeIn"
              data-wow-delay="0.8s"
            >
              <HashLink className="learnbutttt " to="#product">
                Learn More{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M7.99958 2.00049L7.99958 12.2931L4.35324 8.64675L3.64624 9.35371L8.49957 14.2071L13.3529 9.35371L12.6459 8.64675L8.99955 12.2931L8.99955 2.00049H7.99958Z"
                    fill="black"
                  />
                </svg>
              </HashLink>
              <a
                href="\Prodex Whitepaper.pdf"
                target="_blank"
                className="readbutttt"
              >
                Read Whitepaper
                <img
                  src="\assets\down-arrow.svg"
                  alt="img"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
