import React from "react";
import "./roadmap.scss";

const Roadmap = () => {
  return (
    <>
      <section className="mainbanner6" id="roadmap">
        <div className="mainparent6">
          <div className="parent6">
            <p
              className="para1 wow animate__animated animate__zoomIn"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              Roadmap
            </p>
            <p className="para2">////</p>
          </div>
          <div className="headoffroads">
            <h1
              className="head1 animate__animated animate__fadeInBottomRight"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              Prodex Roadmap
            </h1>
            <div className="headofroad">
              <img className="stickdivv" src="\assets\stick.png" />
              <div>
                <img
                  className="phonestickkk d-none"
                  src="\assets\phonestick.png"
                />
                {/* <p className="textmiddd">Year 1</p> */}
                {/* <p className="monthdiv d-none">Year 1</p> */}
                <div
                  className="wow animate__animated animate__fadeInLeft"
                  // data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p className="head2">Phase 01</p>
                  <p className="head3">Initial Launch</p>
                  <p className="head3">Website </p>
                  <p className="head3">Whitepaper</p>
                  <p className="head3">Roadmap</p>
                </div>
              </div>
              <div
                className="headofroad2 wow animate__animated animate__fadeInRight"
                data-wow-delay="0.2s"
              >
                <p className="head4">Quarter 2-2024</p>
                <p className="head5">Soft Launch</p>
                <p className="head5">Website</p>
                <p className="head5">Lite paper</p>
                <p className="head5">
                  Initial marketing blitz to build user base and gain market
                  traction.
                </p>
                {/* <p className="head6">Quarter 04</p>
                <p className="head7">
                  Conduct alpha and beta testing with selected user groups to
                  refine functionalities and ensure system stability
                </p> */}
              </div>
            </div>
            <div className="parent101">
              <img className="para1img1" src="\assets\stick2.png" />
              <div
                className="paraaa1 wow animate__animated animate__fadeInLeft"
                data-wow-delay="0.2s"
              >
                <img
                  className="phonestickkk d-none"
                  src="\assets\phonestick.png"
                />
                <div>
                  <h1 className="para89 warrry">Phase 02</h1>
                  <p className="para80">Official Launch </p>
                  <p className="para80">Platform: Product Catalog Listing</p>
                </div>
              </div>
              <div
                className="lastdivvvv wow animate__animated animate__fadeInRight"
                data-wow-delay="0.2s"
              >
                <h1 className="head4">Quarter 3-2024</h1>
                <p className="para69">
                  Complete the design and development of core functionalities,
                  and community voting mechanisms for platform governance.
                </p>
              </div>
            </div>
            <div className="parent101">
              <img className="para1img11" src="\assets\stick3.png" />
              <div className="paraaa16">
                {/* <p className="textmidddd">Year 2</p> */}
                <img
                  className="phonestickkk d-none"
                  src="\assets\phonestick.png"
                />
                {/* <p className="monthdiv d-none">Year 2</p> */}
                <div
                  className="wow animate__animated animate__fadeInLeft"
                  data-wow-delay="0.2s"
                >
                  <h1 className="para89">Phase 03</h1>
                  <p className="para80">
                    {" "}
                    Expansion and Enhancement: <br /> Store Listing Platform
                  </p>
                </div>
              </div>
              <div
                className="lastdivvvvt  wow animate__animated animate__fadeInRight"
                data-wow-delay="0.2s"
              >
                <h1 className="head4">Quarter 4-2024</h1>
                <p className="para69">
                  Introduce additional features such as Store Listing Platform,
                  advanced search and filtering options, and community voting
                  mechanisms for platform governance.
                </p>
              </div>
            </div>
            <div className="parent101">
              <img className="para1img111" src="\assets\stick4.png" />
              <div className="paraaa16">
                {/* <p className="textmiddddd">Year 3-4</p> */}
                <img
                  className="phonestickkk d-none"
                  src="\assets\phonestick.png"
                />
                {/* <p className="monthdiv d-none">Year 3-4</p> */}
                <div
                  className=" wow animate__animated animate__fadeInLeft"
                  data-wow-delay="0.2s"
                >
                  <h1 className="para89">Phase 04</h1>
                  <p className="para80">Product Listing Platform</p>
                </div>
              </div>
              <div
                className="lastdivvvvt  wow animate__animated animate__fadeInRight"
                data-wow-delay="0.2s"
              >
                <h1 className="para79">QUARTER 1-2025</h1>
                <p className="para69">
                  Expanding our platform by adding Product Listing platform,
                  where users, supplier and manufacturer can list product for
                  selling, including blockchain integration, user interface, and
                  basic transaction systems
                </p>
                <p className="para69">
                  Ongoing: Optimize operational efficiencies, scale up marketing
                  efforts, and expand into new markets and regions.
                </p>
                <h1 className="para79 vybbb">Strategic Partnerships</h1>
                <p className="para69">
                  Forge alliances with key industry players to enhance service
                  offerings and platform capabilities.
                </p>
              </div>
            </div>
            {/* <div className="parent101">
              <img className="para1img1111" src="\assets\stick4.png" />
              <div className="paraaa16">
              <p className="textmiddddd">Year 5 & Beyond</p>
              <img  className="phonestickkk d-none" src="\assets\phonestick.png"/>
              <p className="monthdiv d-none">Year 5 & Beyond</p>
              <div className=" wow animate__animated animate__backInLeft" data-wow-duration="1s" data-wow-delay="1s" >
                <h1 className="para89">Phase 05</h1>
                <p className="para80">Sustainability and Innovation</p>
                </div>
              </div>
              <div className="lastdivvvvt wow animate__animated animate__backInRight"  data-wow-duration="1s" data-wow-delay="1s">
                <h1 className="para79">Innovation</h1>
                <p className="para69">
                  Continuous exploration of new technologies and features to
                  keep the platform at the forefront of the e-commerce and
                  blockchain industry.
                </p>
                <h1 className="para79 vybbb">Sustainability</h1>
                <p className="para69">
                  Focus on sustainable practices, both in business operations
                  and through integrating eco-friendly initiatives within the
                  platform.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
